import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, ɵConsole, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmpusaQuestionDialog, AuthenticationService, User, Site, Tenant } from '@empusa/empusa-core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TenantService } from '../../../client/tenant.service';
import { SiteService } from '../../../client/site.service';
import { EditTenantComponent } from '../edit/microapp-usermanagement.edit-tenant.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MicroappTenantManListFilter } from './microapp-usermanagement.list-tenant.component.filter';
import { MicroappusermanagementGenericComponent } from '../../generic/microapp-usermanagement-generic.component';
import { UserService } from '../../../client/user.service';
import { domainToASCII } from 'url';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserTenant } from '../../../client/dto/usTenant';
import { CountUsers } from '../../../client/dto/userCount';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'microapp-usermanagement-tenant-list',
  templateUrl: './microapp-usermanagement.list-tenant.component.html',
  styleUrls: ['./microapp-usermanagement.list-tenant.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})

export class ListTenantComponent implements OnInit {

  displayedColumns: string[] = ['name', 'description', 'actions'];
  displayedColumns2: string[] = ['users', 'managements', 'total'];
  dataSource: MatTableDataSource<UserTenant>;
  currentUser: User;
  showBtnNew: boolean = false;
  filterGroup: FormGroup;
  userFilter = new MicroappTenantManListFilter;
  loading: boolean;
  tenantId: string;
  allRowsExpanded: boolean = false;
  expandedElement: CountUsers;
  contUsers: CountUsers;
  isSuperAdmin: boolean;
  billingEnabledIntegration: boolean = true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private siteService: SiteService, private tenantService: TenantService, private userService: UserService,
    private dialog: MatDialog, private snackBar: MatSnackBar, private formBuilder: FormBuilder,
    private translate: TranslateService, private auth: AuthenticationService,
    @Inject('environment') private environment) {
    auth.loadCurrentUser().then(user => {
      this.currentUser = user;
      this.showBtnNew = true;
      this.billingEnabledIntegration = this.environment.billing_integration_enabled;
      if (this.billingEnabledIntegration) {
        this.displayedColumns.unshift('billingTenantId')
      }
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      this.viewTenants();
    });
  }

  ngOnInit() {
    //we set the datasource of the table
    this.filterGroup = this.buildFilter();
  }

  /**
   * @name updateUsers
   * @description update users from the database
   */
  viewTenants() {
    if (this.currentUser.sites != null && this.currentUser.sites.length > 0) {
      this.loading = true;
      this.siteService.getSite(this.currentUser.sites[0].uid).subscribe((data) => {
        this.loading = false;
        let tempData = [];
        data.tenants.forEach(element => {
          if (!element.is_site) {
            let usTenant = new UserTenant(this.userService, element);
            tempData.push(usTenant);
          }
        });
        this.dataSource = new MatTableDataSource(tempData);
        this.defineDataSourceFilter(this.dataSource);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }
  }

  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */
  buildFilter() {
    return this.formBuilder.group({
      GENERAL: new FormControl('')
    })
  }

  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: UserTenant, filter: string) => {
        const theFilter: MicroappTenantManListFilter = JSON.parse(filter);
        let generalFilter = false;
        if (!theFilter) return true;
        //general Filter
        if (theFilter.general) {
          if (!this.billingEnabledIntegration) {
            if (aGroup.tenant.description != null) {
              if (aGroup.tenant.name.toLowerCase().indexOf(theFilter.general) !== -1) {
                generalFilter = true;
              }
              if (aGroup.tenant.description.toLowerCase().indexOf(theFilter.general) !== -1) {
                generalFilter = true;
              }
            }
            else if (aGroup.tenant.name.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
          } else {
            if (aGroup.tenant.description != null) {
              if (aGroup.tenant.name.toLowerCase().indexOf(theFilter.general) !== -1) {
                generalFilter = true;
              }
              if (aGroup.tenant.description.toLowerCase().indexOf(theFilter.general) !== -1) {
                generalFilter = true;
              }
              if (aGroup.tenant.billingTenantId.toLowerCase().indexOf(theFilter.general) !== -1) {
                generalFilter = true;
              }
            }
            else if (aGroup.tenant.name.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            else if (aGroup.tenant.billingTenantId.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
          }
        } else generalFilter = true;

        return generalFilter;
      }
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    this.userFilter.general = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  /**
   * @name deleteTenant
   * @description delete the current selected tenant
   * @param {string} the tenant id to delete
   */
  deleteTenant(uid: string) {
    let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.tenantService.deleteTenant(uid).subscribe(
          data => {
            for (let i = 0; i < this.dataSource.data.length; ++i) {
              if (this.dataSource.data[i].tenant.uid === uid) {
                const data = this.dataSource.data;
                data.splice(i, 1);
                this.dataSource.data = data;
              }
            }
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.CONFIRMATION"),
              this.translate.instant("DIALOG.DELETE.OK"), {
              duration: 5000,
            });
          },
          error => {
            this.loading = false;
            if (error.status === 409) {
              this.snackBar.open(this.translate.instant("DIALOG.DELETE.TENANT.USER"),
                this.translate.instant("DIALOG.DELETE.OK"), {
                duration: 5000,
              });
            } else if (error.status === 404) {
              this.snackBar.open(this.translate.instant("DIALOG.DELETE.NOTFOUND"),
                this.translate.instant("DIALOG.DELETE.NOT"), {
                duration: 5000,
              });
            }
            else {
              this.snackBar.open(this.translate.instant("DIALOG.DELETE.ERROR"),
                this.translate.instant("DIALOG.DELETE.ERROR.TITLE"), {
                duration: 5000,
              });
            }
          }
        );
      }
    });
  }

  createOrEdit(ticket_id: string) {
    let dialogAssign = this.dialog.open(EditTenantComponent, {
      width: '700px',
      data: ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      if (result) {
        this.viewTenants();
        this.resetFilters();
      }
    });
  }
}

