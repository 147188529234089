import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthenticationService, User, Tenant, Site } from '@empusa/empusa-core';
import * as vmsReducer from '../../../../lib/microapp-vms.reducer';
import * as newVisitActions from '../../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage.actions';
import { MicroappVmsVisitVisitor } from '../../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit-visitor.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { startWith, map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MicroappVmsVisitWithVisitors } from '../../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit.model';
import { getMasterConfig } from '../../../../lib/logbook/store/microapp-vms-logbook-selectors';
import { VmsMasterConfiguration } from '../../../../lib/model/microapp-vms-config.model';
import { MICROAPP_EPS_NAME, MICROAPP_VMS_NAME, VmsConfig } from '../../../../lib/microapp-vms-config';
import { EpsServiceService } from '../../../../client/epsService/eps-service.service';


@Component({
  selector: 'empusa-microapp-vms-select-participants',
  templateUrl: './microapp-vms-select-participants.component.html',
  styleUrls: ['./microapp-vms-select-participants.component.css']
})
export class MicroappVmsSelectParticipantsComponent implements OnInit, OnDestroy {

  @Input() mode: string = "create-visit";
  nonSchedulerParking: boolean;
  host: User;
  tenantList: Tenant[] = [];
  //Participantes en la visita
  visitorsParticipants: MicroappVmsVisitVisitor[] = [];
  //Store
  storeSuscripcion: Subscription;
  visitorsSelector: MicroappVmsVisitVisitor[];
  //Form
  formGroup: any;
  visitorSelectorControl = new FormControl();
  tenantControl = new FormControl([Validators.required]);
  allParkinControl = new FormControl({ disabled: true });
  tooltipEnabledNonEntitled: string = '';

  filteredVisitors: Observable<MicroappVmsVisitVisitor[]>;
  subsVisitors: Subscription;
  subsTenant: Subscription;
  selectedTenant: Tenant;
  currentSiteSubscription: Subscription;

  displayedColumns: string[] = ['img', 'name', 'company', 'email', 'parking', 'actions'];
  participantsDataSource: MatTableDataSource<MicroappVmsVisitVisitor>;
  subsAllParking: Subscription;
  initDone: boolean = false;
  masterCfg: VmsMasterConfiguration;
  can_request_parking: boolean;
  eps_is_active:boolean;
  enabled_non_entitled: boolean;
  billingEnabledIntegration: boolean = true;
  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private translate: TranslateService,
    private vmsConfig: VmsConfig,
    private epsService: EpsServiceService,
    private authservice: AuthenticationService,
    @Inject('environment') private environment) {
      this.store.pipe(select(getMasterConfig)).pipe(take(1)).subscribe(config =>{
        this.masterCfg = config;
        this.can_request_parking = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_CAN_REQUEST_PARKING);
        this.eps_is_active = this.authservice.canUserExecute(MICROAPP_EPS_NAME, this.vmsConfig.F_EPS_RESERVATION)
        this.billingEnabledIntegration = this.environment.billing_integration_enabled;
        console.log("eps is active", this.eps_is_active)
        console.debug("Can request parking:", this.can_request_parking);
        if (!this.masterCfg.parking_active || !this.can_request_parking){          let i = this.displayedColumns.indexOf('parking');
          this.displayedColumns.splice(i,1);
        }
      });
    }


  ngOnInit(): void {
    this.loadPage();
    this.currentSiteSubscription = this.authservice.getEvent_CurrentSite().subscribe(newSite => {
      this.loadPage();
    });
  }

  loadPage(): void {
    this.unsubscribe();
    const user: User = this.authservice.getCurrentUser();
    let tenantId = user.sites[0].tenants[0].uid;
    this.epsService.tenant_quotas(tenantId).subscribe((tenant_quota : any) =>{
      this.enabled_non_entitled = tenant_quota.non_entitled_associations_enabled;
    })
    if(this.enabled_non_entitled == true){
      this.tooltipEnabledNonEntitled =  this.translate.instant('MAPP-VMS-NEW-VISIT-PARTICIPANTS.DISABLED-PARKING');
    }else {
      this.tooltipEnabledNonEntitled = "";
    }
    this.host = user;
    const actualSite = this.authservice.getCurrentSite();
    this.tenantList = user.sites.find(userSite => userSite.uid == actualSite.uid).tenants;
    this.buildForm();

    //Suscription to input visitor
    this.subscriptionToInputVisitor();


    //Suscription to input tenant
    if (this.mode == "create-visit")
      this.subscriptionToTenant(actualSite, user, user);

    // suscripcion al store
    this.subscriptionToStore();
  }


  subscriptionToInputVisitor() {
    this.subsVisitors = this.visitorSelectorControl.valueChanges.subscribe(newValue => {
      if (!newValue || !(newValue.email)) return;
      let visitor: MicroappVmsVisitVisitor = { ...newValue };
      visitor.complimentary_parking = false;
      this.store.dispatch(newVisitActions.addingAParticipant({ visitor }))
      this.visitorSelectorControl.reset();
    });
  }

  subscriptionToStore() {
    this.visitorsParticipants = null;
    this.visitorsSelector = null;
    this.filteredVisitors = null;
    this.storeSuscripcion = this.store.select('groupVisitManage').subscribe(datos => {
      // tras cargar los visitors de un tenant

      if (datos.visit) {
        this.visitorsParticipants = datos.visit.visitors;
        this.participantsDataSource = new MatTableDataSource<MicroappVmsVisitVisitor>(datos.visit.visitors);

        if (this.visitorsParticipants && this.visitorsParticipants.length > 0) {
          let checked = true;
          let atLeastOne = false;
          this.visitorsParticipants.forEach(participant => {
            //if (participant.iu != null && participant.iu.length > 0) {
              atLeastOne = true;
              
              console.log("complimentary_parking", participant.complimentary_parking)
              checked = checked && participant.complimentary_parking;
            //}
          });
          checked = checked && atLeastOne;
          this.formGroup.patchValue({ allParkinControl: checked });
          this.formGroup.controls['allParkinControl'].enable();
        } else {
          this.formGroup.controls['allParkinControl'].disable();
          this.formGroup.patchValue({ allParkinControl: false });
        }
      }

      if (datos.tenantVisitors) {
        this.visitorsSelector = datos.tenantVisitors.slice();
        this.filteredVisitors = this.visitorSelectorControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : (value != null ? value.name : '')),
            map(name => name ? this._filter(name) : this.visitorsSelector.slice())
          );
      }

      if (!datos.loading && !this.initDone && datos.visit && datos.tenantVisitors) {
        this.initDone = true;
        this.store.dispatch(newVisitActions.deleteVisitorForSelection({ visitors: datos.visit.visitors }));
        this.formInit(datos.visit);
      }
    });
  }

  formInit(visit: MicroappVmsVisitWithVisitors) {
    switch (this.mode) {
      case 'update-visit': {
        this.selectedTenant = this.tenantList.find(tenant => tenant.uid == visit.tenant_id);
        if (this.tenantList.length > 1) {
          this.formGroup.controls['tenantControl'].disable();
          this.formGroup.controls['tenantControl'].setValue(this.selectedTenant);
        }
        break;
      }
      default: { //create-visit
        //statements;
        break;
      }
    }
  }

  subscriptionToTenant(actualSite: Site, host: User, user: User) {
    this.selectedTenant = null;
    if (this.tenantList.length > 1)
      this.subsTenant = this.tenantControl.valueChanges.subscribe(newValue => {
        if (!newValue) return;
        this.selectedTenant = newValue;
        this.store.dispatch(newVisitActions.startNewVisit({ site: actualSite,
                            tenant: this.selectedTenant, host: user, user: user}));

      });
    else {
      this.selectedTenant = this.tenantList[0];
      this.store.dispatch(newVisitActions.startNewVisit({ site: actualSite,
                                tenant: this.selectedTenant,
                                host: user, user: user}));
    }
  }

  private _filter(name: string): MicroappVmsVisitVisitor[] {
    const filterValue = name.toLowerCase();
    return this.visitorsSelector.filter(option => {
      return (option.first_name && option.first_name.toLowerCase().indexOf(filterValue) >= 0)
        || (option.last_name && option.last_name.toLowerCase().indexOf(filterValue) >= 0)
        || (option.email && option.email.toLowerCase().indexOf(filterValue) >= 0)
    });
  }

  ngOnDestroy(): void {
    if (this.currentSiteSubscription) {
      this.currentSiteSubscription.unsubscribe
    }
    this.unsubscribe();
  }

  unsubscribe(): void {
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe();
    }
    if (this.subsVisitors) {
      this.subsVisitors.unsubscribe();
    }
    if (this.subsTenant) {
      this.subsTenant.unsubscribe();
    }
  }

  displayNewVisit() {
    this.store.dispatch(newVisitActions.showNewParticipant());
  }

  displayFn(visitor: MicroappVmsVisitVisitor): string {
    return visitor && visitor.first_name ? visitor.first_name + " " + visitor.last_name : '';
  }

  onComplimentaryParking(visitor: MicroappVmsVisitVisitor) {
    this.store.dispatch(newVisitActions.complimentaryParkingRequest({ visitor, c_parking: !visitor.complimentary_parking }))
  }

  onDeleteParticipant(visitor: MicroappVmsVisitVisitor) {
    this.store.dispatch(newVisitActions.deletingAParticipant({ visitor }));
  }


  private buildForm() {
    if (this.tenantList.length > 1) {
      this.formGroup = new FormGroup({
        visitorSelectorControl: this.visitorSelectorControl,
        allParkinControl: this.allParkinControl,
        tenantControl: this.tenantControl,
      });
    } else {
      this.formGroup = new FormGroup({
        visitorSelectorControl: this.visitorSelectorControl,
        allParkinControl: this.allParkinControl,
      });
    }
    this.formGroup.controls['allParkinControl'].disable();
    this.formGroup.patchValue({ allParkinControl: false });

  }

  onClickallParkinControl() {
    this.store.dispatch(newVisitActions.complimentaryParkingRequest4All({ c_parking: this.formGroup.controls['allParkinControl'].value }))
  }

}

