import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@empusa/empusa-core';
import { MICRAPP_ACCESS_USERS, MICROAPP_NAME } from '../public-api';
// import { MICROAPP_NAME, MICRAPP_ACCESS_USERS } from '../lib/microapp-usermanagement.module';


@Component({
  selector: 'microapp-usermanagement',
  templateUrl: './microapp-usermanagement.user.component.html',
  styleUrls: ['./microapp-usermanagement.user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {

  isSuperAdmin: boolean = false;
  isAdmin: boolean;
  isManagement: boolean;
  siteName: string;
  loading: boolean;
  can_access: boolean = false;
  billing_integration: boolean;
  constructor(private auth: AuthenticationService, private router: Router,
    @Inject('environment') private environment) {
  }

  ngOnInit() {
    this.siteName = this.auth.getCurrentSite().name;
    this.can_access = this.auth.canUserExecute(MICROAPP_NAME, MICRAPP_ACCESS_USERS)
    this.loading = true;
    this.billing_integration = this.environment.billing_integration_enabled;
    this.auth.loadCurrentSite().then(site => {
      this.isManagement = this.auth.hasCurrentUserRole("MANAGEMENT");
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.loading = false;
      this.siteName = site.name;
    });
  }
}
