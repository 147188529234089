import { Component, Inject, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { Tenant, AuthenticationService, User, Site } from '@empusa/empusa-core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '../../../client/tenant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolService } from '../../../client/rol.service';
import { SiteService } from '../../../client/site.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../client/user.service';
import { MicroappusermanagementGenericComponent } from '../../generic/microapp-usermanagement-generic.component';
import { MatTableDataSource } from '@angular/material/table';
import { EditUserComponent } from '../../user/edit/microapp-usermanagement.edit-user.component';
import { MatPaginator } from '@angular/material/paginator';
import { Floor } from '@empusa/empusa-core/auth/floor';
import { AccessRigths } from '@empusa/empusa-core/auth/accessRights';
import { AccessGroup } from '@empusa/empusa-core/auth/accessGroup';
// import { AccessGroup } from '../../../../../../node_modules/@empusa/empusa-core/auth/accessGroup';

@Component({
  selector: 'empusa-microapp-usermanagement.edit-tenant',
  templateUrl: './microapp-usermanagement.edit-tenant.component.html',
  styleUrls: ['./microapp-usermanagement.edit-tenant.component.css']
})
export class EditTenantComponent {

  /** form to validate */
  tenantForm: FormGroup;
  /** the tenant being edited */
  editTenant: Tenant = new Tenant();
  matcher = new MyErrorStateMatcher();
  currentUser: User;
  loading: boolean;
  floorSub: string;
  users = [];
  tenantBillingId = []
  tenantId: string;
  floorList: Floor[] = [];
  accessGroupList: AccessGroup[] = [];
  accessList: AccessRigths[] = [];
  displayedColumns: string[] = ['mail', 'fullname', 'rol', 'actions'];
  dataSource = new MatTableDataSource([]);
  site: string;
  building: Site;
  showAccessGroup: boolean = false;
  billingEnabledIntegration: boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activatedRoute: ActivatedRoute, private tenantService: TenantService,
    private userService: UserService, private dialog: MatDialog,
    private snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditTenantComponent>,
    private translate: TranslateService, private router: Router,
    private formBuilder: FormBuilder, private location: Location, private rolService: RolService,
    private siteService: SiteService, private auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public ticket_id: string,
    @Inject('environment') private environment) {

    this.tenantForm = this.formBuilder.group({
      uid: [],
      name: ['', [Validators.required, Validators.pattern('^[^()]+$')]],
      floor: ['', Validators.required],
      // sublocation: ['', Validators.required],
      description: [''],
      access: ['', Validators.required],
      accessGroup: ['', Validators.required],
      billingTenantId: ['', Validators.compose(this.quantityValidator())]
    });
    this.showAccessGroup = this.environment.show_acess_group;
    this.billingEnabledIntegration = this.environment.billing_integration_enabled;
    this.auth.loadCurrentSite().then(site => {
      this.site = site.uid;
      this.building = site;
      if (this.billingEnabledIntegration) {
        this.tenantService.getTenantsYardi(this.site).subscribe(data => {
          this.tenantBillingId = data.leases;
          this.tenantBillingId.forEach(tenant => {
            tenant.billingTenantName = tenant.billingTenantName.replace("(", "-").replace(")", "-")
          })
          this.tenantBillingId.sort((a, b) =>
            a.billingTenantName.toLowerCase() > b.billingTenantName.toLowerCase() ? 1 :
              a.billingTenantName.toLowerCase() < b.billingTenantName.toLowerCase() ? -1 :
                0
          );
        })
      }
    })

    // getTenantsYardi

    auth.getUser().then(user => {
      this.currentUser = user;

      //getting the user info from backend

      // this.activatedRoute.params.subscribe(params => {
      //   let tenant_id: string = params['id'];

      // if (this.building.name == "Razer") {
      //   this.showAccessGroup = true;
      // }

    

      if (this.showAccessGroup) {
        this.tenantService.getAccessGroup().subscribe(resp => {
          for (let i = 0; i < resp.access_groups.length; i++) {
            this.accessGroupList.push(resp.access_groups[i]);
          }
        })
      }
      this.tenantService.getFloor().subscribe(resp => {
        for (let i = 0; i < resp.home_floors.length; i++) {
          this.floorList.push(resp.home_floors[i]);
        }
      });

      this.tenantService.getAccess().subscribe(resp => {
        for (let i = 0; i < resp.access_rights.length; i++) {
          this.accessList.push(resp.access_rights[i]);
        }
      })



      let tenant_id: string = ticket_id;
      if (tenant_id) {
        //we are editting
        this.loading = true;
        this.tenantService.getTenant(tenant_id).subscribe(resp => {

          this.editTenant = resp;
          this.g.uid.setValue(resp.uid);
          this.g.name.setValue(resp.name);
          this.g.description.setValue(resp.description);
          if (resp.accessRights != null) {
            let access_id: string[] = [];
            for (let i = 0; i < resp.accessRights.length; i++) {
              access_id.push(resp.accessRights[i].AccessRightId);
            }
            this.g.access.setValue(access_id);
          }

          if (resp.floor != null) {
            let floor_id: string[] = [];
            for (let i = 0; i < resp.floor.length; i++) {
              floor_id.push(resp.floor[i].HomeFloorId);
            }
            this.g.floor.setValue(floor_id);
          }

          if (this.showAccessGroup) {
            if (resp.accessGroups != null) {
              let access_id: string[] = [];
              for (let i = 0; i < resp.accessGroups.length; i++) {
                access_id.push(resp.accessGroups[i].AccessGroupId);
              }
              this.g.accessGroup.setValue(access_id);
            }
          } else {
            this.g.accessGroup.setValue('no');
            // } else {

          }

          if (this.billingEnabledIntegration) {
            this.g.billingTenantId.setValue(resp.billingTenantId);
          } else {
            this.g.billingTenantId.setValue("t0000000");
          }

          this.tenantId = tenant_id;
          // this.getUsers();
          this.loading = false;
        });

      } else {
        //we are adding a new tenant
        this.editTenant = new Tenant();
        this.g.uid.setValue("");
        this.g.name.setValue("");
        this.g.description.setValue("");
        this.g.floor.setValue("");
        let accessGrouInit = "";
        if (!this.showAccessGroup) {
          accessGrouInit = "no"
        }
        this.g.accessGroup.setValue(accessGrouInit);
        // this.g.sublocation.setValue("");
        if (this.billingEnabledIntegration) {
          this.g.billingTenantId.setValue("");
        } else {
          this.g.billingTenantId.setValue("t0000000");
        }
      }
      // });
    });

  }

  // convenience getter for easy access to form fields
  get g(): any {
    return this.tenantForm.controls;
  }


  onSubmit() {
    // stop here if form is invalid
    if (this.tenantForm.invalid) {
      return;
    }
    this.loading = true;
    this.editTenant.name = this.g.name.value;
    // this.editTenant.name = this.editTenant.name.trim();
    this.editTenant.description = this.g.description.value;
    this.editTenant.floor = this.g.floor.value;
    this.editTenant.accessRights = this.g.access.value;
    // this.editTenant.sublocation = this.g.sublocation.value;
    if (this.billingEnabledIntegration) {
      this.editTenant.billingTenantId = this.g.billingTenantId.value;
    } else {
      this.editTenant.billingTenantId = "";
    }
    this.editTenant.is_site = false;
    if (this.showAccessGroup) {
      this.editTenant.accessGroups = this.g.accessGroup.value;
    }
    if (this.editTenant.uid) {
      //we are editting
      this.tenantService.updateTenant(this.editTenant, this.currentUser.sites[0].dn).subscribe(
        data => {
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.TITLE"), {
            duration: 5000,
          });
          this.loading = false;
          this.dialogRef.close(this.editTenant);
        },
        error => {
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.TITLE"), {
            duration: 5000,
          });
        });
    } else {
      //we are creating a new one
      this.tenantService.addTenant(this.editTenant, this.currentUser.sites[0].dn).subscribe(
        data => {
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.TITLE"), {
            duration: 5000,
          });
          this.loading = false;
          this.dialogRef.close(this.editTenant);
        },
        error => {
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.TITLE"), {
            duration: 5000,
          });
        });
    }
  }

  selectedTenant(tenantBillingID) {
    this.g.billingTenantId.setValue(tenantBillingID)
  }

  /**
 * @name toBack
 * @description cancel the edition
 */
  toBack() {
    this.location.back();
  }


  quantityValidator(): any[] {
    let validatorArr: any[] = [];
    validatorArr.push(Validators.required);
    validatorArr.push(Validators.min(8));
    validatorArr.push(Validators.pattern("[t][0-9]{7}"));
    validatorArr.push(Validators.maxLength(8));
    return validatorArr;
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
