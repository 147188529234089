<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<h2 class="cabecera">
  <mat-icon class="iconoCabecera">supervised_user_circle</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-USERMANAGEMENT.HOME.HEAD' | translate}}</mat-label>
</h2>

<div class="fondo">
  <div class="centerHeaderNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab *ngIf="isSuperAdmin || isAdmin" label="{{'MAPP-USERMANAGEMENT.HOME.TAB.USERS' | translate}}">
        <microapp-usermanagement-user-list></microapp-usermanagement-user-list>
      </mat-tab>
      <mat-tab *ngIf="isSuperAdmin || isAdmin" label="{{'MAPP-USERMANAGEMENT.HOME.TAB.TENANTS' | translate}}">
        <microapp-usermanagement-tenant-list></microapp-usermanagement-tenant-list>
      </mat-tab>
      <mat-tab *ngIf="isSuperAdmin || isAdmin" label="{{'MAPP-USERMANAGEMENT.HOME.TAB.BUILDING' | translate}}">
        <microapp-usermanagement-building-list></microapp-usermanagement-building-list>
      </mat-tab>
      <mat-tab *ngIf="isManagement" label="{{'MAPP-USERMANAGEMENT.HOME.TAB.USERTENANT' | translate}}">
        <microapp-usermanagement-userTenant-list></microapp-usermanagement-userTenant-list>
      </mat-tab>
      <mat-tab *ngIf="(isSuperAdmin || isAdmin) && billing_integration" label="{{'MAPP-USERMANAGEMENT.HOME.TAB.TARIFF-ASSIGNMENT' | translate}}">
        <empusa-tariff-assignment></empusa-tariff-assignment>
      </mat-tab>
    </mat-tab-group>
    <div class="notAccess" *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>