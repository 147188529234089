import { Injectable, Inject } from '@angular/core';
import { User } from './user';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Site } from './site';
import { Subject, Observable, throwError } from 'rxjs';


@Injectable({ providedIn: 'root' })
export abstract class AuthenticationService {

    /** the currently user logged, null if none */
    private currentUser: User = null;
    /** the current selected site */
    private currentSite: Site = null;

    /**
     * List of permissions for each microapp - functionalities - valid roles
     * This object is being cached
     */
    private permissionTree: any = {};

    private selectedSiteEvent: Subject<Site> = new Subject();

    constructor(private http: HttpClient, @Inject('environment') private environment) { }

    /**
     * @name getCurrentUserName
     * @description return the username logged in.  
     * This is perform against the authentication service, without the backend. i.e., retrieven the token info.
     * It is necessary to get the username without the need of async method, directly from the auth mechanism.
     * @return <string> the username
     */
    public abstract getCurrentUserName(): string;

    /**
     * @name getCurrentUserMail
     * @description return the email for the current user logged in.
     * This is perform against the authentication service, without the backend. i.e., retrieven the token info.
     * It is necessary to get the username without the need of async method, directly from the auth mechanism.
     * @return <string> the email of the current logged in user
     */
    public abstract getCurrentUserMail(): string;

    /**
     * @name loadCurrentUser
     * @loadCurrentUser retrieve the current user logged in, null if none. This is an async function, and it caches the user. 
     * If you are a microapp, you should get the user directly with getCurrentUser
     * @return the promise to get the current logged in user
     */
    public async loadCurrentUser(): Promise<User> {
        if (this.currentUser == null) {
            return new Promise<User>((resolve, reject) => {
                this.getUserLogged().subscribe((user) => {
                    this.currentUser = user;
                    resolve(user);
                });
            });
        } else {
            return Promise.resolve(this.currentUser);
        }
    }

    /**
     * Check wether the user can execute certain microapp functionality (inside the microapp!).
     * This function need to be async just to ensure the permissions are loaded from server
     * @param <string> microapp - the microapp name
     * @param <string> functionality - the functionality to ask
     * @return <boolean> (promise) true if the user can access/execute that functionality, false otherwise
     */
    public async canUserExecuteAsync(microapp: string, functionality: string): Promise<boolean> {
        try {
            if (!this.permissionTree[microapp]) {
                await this.loadMicroappPermissions(microapp);
                if (!this.permissionTree[microapp]) {
                    return false;
                }
            }
        } catch (err) { }
        return this.canUserExecute(microapp, functionality);
    }


    /**
     * @name loadCurrentSite
     * @loadCurrentUser retrieve the current site, null if none. This is an async function, and it caches the site. 
     * If you are a microapp, you should get the site directly with getCurrentSite
     * @return the promise to get the current site
     */
    public async loadCurrentSite(): Promise<Site> {
        if (this.currentSite == null) {
            let currUser = await this.loadCurrentUser();
            return currUser.sites[0];
        } else {
            return Promise.resolve(this.currentSite);
        }
    }

    /**
     * @deprecated use getCurrentUser instead
     */
    public async getUser(): Promise<User> {
        return Promise.resolve(this.getCurrentUser());
    }

    /**
     * @name getCurrentUser
     * @description get the current logged user.
     * @return the currently logged user
     */
    public getCurrentUser(): User {
        if (this.currentUser == null) {
            throwError("None user loaded yet! Should you loadCurrentUser?");
        } else {
            return this.currentUser;
        }
    }

    /**
     * @name getEvent_CurrentSite
     * @description return the event to discover changes at the current site selection.
     * You can subscribe to this subject in order to get next events when changing the current site selection.
     * @returns <Subjet<Site>> an RX Subject to observe selectedSite events
     */
    public getEvent_CurrentSite(): Subject<Site> {
        return this.selectedSiteEvent;
    }

    /**
     * @name getCurrentSite
     * @description retrieve the current selected site
     * @return {Site} the current selected site
     */
    public getCurrentSite(): Site {
        return this.currentSite;
    }

    /**
     * @name setCurrentSite
     * @description set the current selected site
     * @param <Site> the site to set
     */
    public setCurrentSite(site: Site) {
        this.currentSite = site;
        this.selectedSiteEvent.next(site);
    }

    /**
     * Return the currently user logged in
     */
    private getUserLogged(): any {
        return this.http.get(`${this.environment.urlbase}usermanagement/services/users/mine`).pipe(
            map(data => (<any>data).userInfo)
        );
    }

    /**
     * @name hasUserRole
     * @description return if the current user has a concrete rol
     * @param <string> rol the rol to check
     */
    public hasCurrentUserRole(rol: string): boolean {
        let currentUser = this.getCurrentUser();
        for (let i = 0; i < currentUser.roles.length; i++) {
            let irol = currentUser.roles[i];
            if (irol.cn === rol) {
                return true;
            }
        }
        return false;
    }

    /**
     * checkout from the serve the permissions per functionality for a certain microapp.
     * This method should be executed only once. This is done only by the macro app!!
     * @param microapp the microapp name to load
     */
    public async loadMicroappPermissions(microapp: string): Promise<void> {
        if (!this.permissionTree[microapp]) {
            //lets ensure the user is being loaded
            await this.loadCurrentUser();
            this.permissionTree[microapp] = await this.getMicroappPermissions(microapp).toPromise();
        }
    }

    /**
     * just a test method
     */
    public testMethod(): number {
        return 5;
    }

    /**
     * Check wether the user can see/access to a certain microapp (the access functionality)
     * @param <string> microapp - the microapp name
     * @return <boolean> true if the user can see/access the microapp, false otherwise
     */
    public async canAccessMicroapp(microapp: string): Promise<boolean> {
        if (!this.permissionTree[microapp]) {
            await this.loadMicroappPermissions(microapp);
            if (!this.permissionTree[microapp]) {
                return false;
            }
        }

        const ACCESS_FUNCTIONALITY = "access";
        let allPermissions = this.permissionTree[microapp][ACCESS_FUNCTIONALITY];
        let user = await this.loadCurrentUser();
        for (let i = 0; allPermissions && i < user.roles.length; i++) {
            let permission = allPermissions[user.roles[i].cn];
            if (permission) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check wether the user can execute certain microapp functionality (inside the microapp!)
     * @param <string> microapp - the microapp name
     * @param <string> functionality - the functionality to ask
     * @return <boolean> true if the user can access/execute that functionality, false otherwise
     */
    public canUserExecute(microapp: string, functionality: string): boolean {
        try {
            if (!this.permissionTree[microapp]) {
                this.loadMicroappPermissions(microapp);
                if (!this.permissionTree[microapp]) {
                    return false;
                }
            }

            let allPermissions = this.permissionTree[microapp][functionality];
            let user = this.getCurrentUser();
            for (let i = 0; allPermissions && i < user.roles.length; i++) {
                let permission = allPermissions[user.roles[i].cn];
                if (permission) {
                    return true;
                }
            }
        } catch (err) { }
        return false;
    }

    /**
     * Return all the permissions for a certain microapp
     */
    private getMicroappPermissions(microapp: string): Observable<any> {
        let user = this.getCurrentUser();
        let url = `${this.environment.urlbase}microappssecurity/services/microapps/${user.sites[0].dn}/${microapp}`;
        return this.http.get(url).pipe(
            map(data => data)
        );
    }

    /**
     * @name logout
     * @description force the logout of the user
     */
    public abstract _logout();

    public logout() {
        this.currentUser = null;
        this._logout();
    }
}


