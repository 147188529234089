import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User, AuthenticationService, Tenant } from '@empusa/empusa-core';
import { AddUserDTO } from './dto/adduser';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  url: string = this.environment.urlbase + 'usermanagement/services/';
  // url: string =  'http://localhost:5001/usermanagement/services/';
  urlK: string = 'https://alice.boustead.getronicssmartspaces.com/smartbuilding/lift/services/kone/accessrights/list'
  constructor(private http: HttpClient, @Inject('environment') private environment, private auth: AuthenticationService) {
  }

  allUsers(): any {
    return this.http.get(this.url + 'users').pipe(
      map(data => data)
    );
  }

  findUsersByBuilding(uid: string): any {
    return this.http.get(`${this.url}sites/${uid}/users`).pipe(
      map(data => data)
    );
  }

  findUsersByTenant(uid: string): any {
    let id = encodeURIComponent(uid);
    return this.http.get(`${this.url}tenant/${id}/users`).pipe(
      map(data => data)
    );
  }

  getUser(uid: string): any {
    return this.http.get(this.url + 'users/user/' + uid).pipe(
      map(data => (<any>data).userInfo)
    );
  }

  addUser(user: User, noCharge: boolean ) {
    let body = JSON.stringify(new AddUserDTO(user, this.auth.getCurrentUser().sites[0].dn, noCharge));
    console.log("body", body)
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(this.url + 'users/user', body, { headers }).pipe(map(resp => resp));
  }

  updateUser(user: User) {
    let body = JSON.stringify(new AddUserDTO(user, this.auth.getCurrentUser().sites[0].dn, false));
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'users/user/' + user.uid, body, { headers }).pipe(map(resp => resp));
  }

  deleteUser(uid: String) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.delete(this.url + 'users/user/' + uid, { headers }).pipe(map(resp => resp));
  }

  getPhoto(user: User) {
    let pictureURL = this.url + 'users/photo/' + user.uid;
    return this.http.get(pictureURL, {
      responseType: 'arraybuffer'
    });
  }

  getAccess(){
    return this.http.get(this.urlK).pipe(
      map(data => data)
    );
  }

  getRoles(dn: string){
    return this.http.get(this.url+'roles/user/'+dn).pipe(
      map(data => data)
    );
  }

  resentEmail(dn: string){
    return this.http.get(this.url+'users/user/'+dn+'/resendregistrationnotification').pipe(
      map(data => data)
    );
  }

  getAccessByTenant(dn: string): any{
    let id = encodeURIComponent(dn);
    return this.http.get(this.url+'tenants/'+id+'/lift_access_rights').pipe(
      map(data => data)
    );
  }

  getFloorByTenant(dn: string): any{
    let id = encodeURIComponent(dn);
    return this.http.get(this.url+'tenants/'+id+'/home_floor').pipe(
      map(data => data)
    );
  }

  getAccessGroupByTenant(dn: string): any{
    let id = encodeURIComponent(dn);
    return this.http.get(this.url+'tenants/'+id+'/access_group').pipe(
      map(data => data)
    );
  }

  /**
     * Retrieve all the tenants for the current site
     */
   async findAllTenants(): Promise<Tenant[]> {
    let currentSite = await this.auth.loadCurrentSite();
    let httpget = this.http.get(this.url+ 'sites/'+currentSite.uid).pipe(
        map(data => (<any>data).tenants)
    );
    return httpget.toPromise();
}

}
